import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Grid, GridItem, PageTitle, TypographyV2 as Typography } from '@which/seatbelt'
import { dynamicDatalayerPush } from '@which/shared'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useRegisterMutation } from '../../../generated/frontend'
import { ErrorComponent } from '../../../shared/components/Error/ErrorComponent'
import { Link } from '../../../shared/components/Link'
import { useGetQueryString } from '../../../shared/hooks/useGetQueryString'
import { isLocal } from '../../../shared/utils/env-checks'
import { BundleDetails } from '../components/BundleDetails'
import { ErrorModal } from '../components/ErrorModal'
import { FormFields } from '../components/FormFields'
import { MarketingPreferences } from '../components/MarketingPreferences'
import { PasswordFieldView } from '../components/PasswordField'
import { QuestionAccordion } from '../components/QuestionAccordion'
import { RegistrationFields } from '../data'
import {
  accountPagesDataLayer,
  findBlaizeSessionAvailable,
  findWhichDomain,
  setNewRelicTransaction,
} from '../utils'
import style from './RegistrationPage.module.scss'

const RegistrationPage: FunctionComponent = () => {
  const registerReq = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    individual: {
      isPostOptIn: false,
      isEmailOptIn: false,
    },
  }

  const [registerReqObj, setRegisterReqObj] = useState(registerReq)
  const [sessionChecked, setSessionChecked] = useState(false)

  /* @TODO: ee-247 - remove after EE-247 test ends */
  const showBundleDetails = useFeatureIsOn('ee-247-add-selected-subscription-details')
  const bundleId = useGetQueryString('ltm_gaBundleID')

  const bundleDetails = bundleId && showBundleDetails ? <BundleDetails bundleId={bundleId} /> : null

  let httpErrorCode = ''
  let showModal = false
  const [registerMutation, { loading, data, error }] = useRegisterMutation({
    variables: {
      registerRequest: { ...registerReqObj },
    },
  })
  const methods = useForm({ mode: 'onBlur' })
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods

  const accordionContent = (
    <>
      <Typography textStyle="sb-text-body-default-regular">
        If you have any questions about joining Which? please get in touch by phone or email:
      </Typography>
      <Typography textStyle="sb-text-body-default-regular">
        <Link href="tel:+02922670000">02922670000</Link>
        <br />
        Mon - Fri, 8:30am to 6pm
        <br />
        Sat, 9am to 1pm
        <br />
        Standard call rates apply
      </Typography>
      <Link href="mailto:support@which.co.uk">support@which.co.uk</Link>
    </>
  )

  const handleOnSubmit = (formObj) => {
    const req = {
      firstName: formObj.firstName,
      lastName: formObj.lastName,
      email: formObj.email,
      password: formObj.password,
      individual: {
        isPostOptIn: formObj.postConsent === 'Yes' ? true : false,
        isEmailOptIn: formObj.emailConsent === 'Yes' ? true : false,
      },
    }
    setRegisterReqObj(req)
    registerMutation()
  }
  const limioRedirectUrl = useGetQueryString('redirect')

  setNewRelicTransaction('account/registration')
  useEffect(() => {
    if (
      isSubmitting &&
      errors &&
      Object.keys(errors).length !== 0 &&
      Object.getPrototypeOf(errors) === Object.prototype
    ) {
      const fields = Object.keys(errors).join(' | ')
      dynamicDatalayerPush({
        eventCategory: 'Signup',
        eventAction: 'Form Validation Errors',
        eventLabel: fields.toString(),
      })
      const elements = Object.keys(errors)
        .map((name) => document.getElementsByName(name)[0])
        .filter((el) => !!el)
      elements.sort((a, b) => b.scrollHeight - a.scrollHeight)
      elements[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [errors, isSubmitting])

  useEffect(() => {
    if (findBlaizeSessionAvailable('blaize_session')) {
      window.location.href = findWhichDomain(limioRedirectUrl)
    } else {
      setSessionChecked(true)
    }
  }, [limioRedirectUrl])

  if (data && data.register) {
    const { register } = data
    switch (register.__typename) {
      case 'Registration':
        if (register.status === '200' || register.status === '201') {
          const domain = isLocal() ? 'localhost' : '.which.co.uk;'

          const zephrCookies = register.headers['set-cookie']
          zephrCookies.forEach((cookie: string) => {
            if (cookie.indexOf('blaize_') > -1) {
              document.cookie = `${cookie};domain=${domain};`
            } else {
              document.cookie = cookie
            }
          })
          if (register.masterId) {
            document.cookie = `master_id.analytics_flag=1;domain=${domain};`
            document.cookie = `master_id=${register.masterId};domain=${domain};`
          }
          window.location.href = findWhichDomain(limioRedirectUrl) || 'https://www.which.co.uk/'
        }
        break
      case 'RegistrationError':
        httpErrorCode = register.status
        showModal = true
        break
    }
  }

  if (error) {
    const { graphQLErrors } = error
    const extensionObj = graphQLErrors[0].extensions
    if (
      extensionObj?.code === '500' ||
      extensionObj?.code === '400' ||
      extensionObj?.code === '504'
    ) {
      httpErrorCode = extensionObj?.code
      showModal = true
    } else {
      return <ErrorComponent error={error} />
    }
  }

  return sessionChecked ? (
    <>
      <Grid className={style.registrationPage}>
        <ErrorModal
          httpErrorCode={httpErrorCode}
          emailAddress={registerReqObj?.email}
          showModal={showModal}
        />
        <GridItem span={{ medium: 6, large: 6 }} columnStart={{ medium: 4, large: 4 }}>
          {bundleDetails}
          <PageTitle pageTitle="Create an account" pageTitleTag="h1" />
          <Typography
            textStyle="sb-text-body-x-small-regular"
            className={style.registrationPageLogin}
          >
            Already have an account?{' '}
            <Link href="/login" textStyle="sb-text-interface-body-x-small-regular">
              Log in to Which?
            </Link>
          </Typography>
          <FormProvider {...methods}>
            <form method="post">
              <FormFields fields={RegistrationFields} />
              <PasswordFieldView
                displayPasswordCheckList={true}
                name="password"
                label="Password"
                rulesRequired={true}
                calledFrom="registration"
                errorMessageText="Please enter a valid password"
                showRequiredText={true}
                autoComplete="new-password"
                validation={{
                  required: true,
                  pattern:
                    /^(?=.*[a-zA-Z])(?=.*[0-9!#£$%^&*()_+="\-])(?!.*[@#;?<.,>{/}'\\|\[\]]).{8,32}$/,
                }}
              />
              <MarketingPreferences />
              <Button
                data-testid="sign-up-button"
                enableSpinner={loading}
                onClick={handleSubmit((formObj) => handleOnSubmit(formObj))}
                className={style.registrationPageSubmit}
              >
                Save and continue
              </Button>
            </form>
          </FormProvider>
          <QuestionAccordion
            label="Any questions?"
            content={accordionContent}
            calledFrom="registration"
          />
        </GridItem>
      </Grid>
    </>
  ) : (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>Create an account - Which?</title>
        <script>
          {accountPagesDataLayer({
            content_type: 'signup',
            vertical: 'signup',
            contentGroup: 'join/begin',
          })}
        </script>
      </Helmet>
    </>
  )
}
export default RegistrationPage
